:root {
  --parkGreen: #88C659;
  --parkGreenLight: #dbeecd;
  --parkBlue: #59A7C6;
  --parkPurple: #6859C6;
  --parkPink: #C559C6;
  --parkRed: #C65969;
  --parkYellow: #C6A659;
  --lightGrey: #ddd;
  --darkGrey: #666666;
  --bs-focus-ring-color: #dbeecd;
}

a {
  text-decoration: none;
}

.parks-green {
  color: var(--parkGreen) !important;
}

.parks-green-back {
  background-color: var(--parkGreen);
}

.parks-green-light {
  color: var(--parkGreenLight) !important;
}

.parks-green-light-back {
  background-color: var(--parkGreenLight);
}

.errorText {
  color: var(--parkRed);
  font-size: 13px;
  text-align: center;
}

textarea:focus,
input:focus,
.form-select:focus,
.form-control:focus,
.uneditable-input:focus {
  border-color: rgba(136, 198, 89, 0.8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(136, 198, 89, 0.6);
  outline: 0 none;
}

.btn {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-black);
  --bs-btn-border-color: var(--parkGreen);
  --bs-btn-hover-color: var(--bs-black);
  --bs-btn-hover-bg: var(--parkGreenLight);
  --bs-btn-hover-border-color: var(--parkGreenLight);
  --bs-btn-active-color: var(--bs-black);
  --bs-btn-active-bg: var(--parkGreenLight);
  --bs-btn-active-border-color: var(--parkGreenLight);
}

.btn-primary {
  --bs-btn-bg: var(--parkGreen);
}

.btn-outline-primary {
  --bs-btn-bg: transparent;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  background-color: var(--lightGrey);
  border-color: var(--lightGrey);
}

.form-check-input:checked {
  background-color: var(--parkGreen);
  border-color: var(--parkGreen);
}

.logoBack {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0;
}

.logoBack::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/img/Parks_logo.svg");
  background-repeat: no-repeat;
  background-position: calc(0% - 75vh) calc(0% - 75vh);
  background-size: 150vh 150vh;
  opacity: 0.3;
  z-index: -1;
}

.login-page-wrap.logoBack::after {
  opacity: 1;
}


.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.modalBackdrop {
  background: black;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: .6;
}

#park-login h2,
#park-login input,
#park-login textarea {
  margin-bottom: 20px;
  height: 50px;
  padding: 10px 0;
  text-transform: capitalize;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
  border-bottom: 2px solid #c1c1c1;
  color: #58595B;
}

#park-login h2 {
  border: none;
}

#park-login textarea {
  height: 120px;
}

#park-login .form-group {
  position: relative;
}

#park-login .form-group label {
  position: absolute;
  text-transform: capitalize;
  top: 20px;
  left: 0;
  pointer-events: none;
  font-size: 14px;
  color: #58595B;
  margin-bottom: 0;
  transition: all .6s;
}

#park-login input:focus~label,
#park-login textarea:focus~label,
#park-login input:valid~label,
#park-login textarea:valid~label {
  top: -5px;
  opacity: 0;
  left: 0;
  color: var(--parkGreen);
  font-size: 12px;
  color: #07315B;
  font-weight: bold;
}

#park-login input:focus,
#park-login textarea:focus,
#park-login input:valid,
#park-login textarea:valid {
  border-bottom: 2px solid var(--parkGreen);
  box-shadow: none;
}

#park-login .form-text {
  margin-top: 30px;
}

#park-login .myform-button {
  margin-top: 30px;
}

#park-login .myform-button .myform-btn {
  width: 100%;
  height: 50px;
  font-size: 17px;
  background: var(--parkGreen);
  border: none;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  transition: all .5s;
}

#park-login .myform-button .myform-btn:hover {
  background: #fff;
  color: var(--parkGreen);
  border: 1px solid var(--parkGreen);
}

#park-login .rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.demoText {
  font-size: 12px;
  color: darkGrey;
}


.cna-card {
  border: 1px solid var(--parkGreen) !important;
}

.cna-card * {
  color: #fff;
}

.cna-card .card-title {
  font-weight: 400;
}

.cna-card:hover * {
  color: var(--parkGreen);
}

.cna-card:hover {
  background-color: #fff !important;
  border: 1px solid var(--parkGreen) !important;
}

#record-map-wrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
}

#record-map-wrapper #map {
  height: 85vh;
  /* You can adjust the height as necessary */
  border: 1px solid #ddd;
}

#record-map-wrapper #mySidenav {
  transform: translateX(0%);
  position: relative;
  /* updated */
  z-index: 1;
  height: 85vh;
  background-color: #fff;
  transition: 0.5s;
  overflow: visible;
  border: 1px solid #ddd;
  border-left: none;
  max-width: 500px;
}

#record-map-wrapper .sideContent {
  overflow: auto;
  height: 100%;
}

#record-map-wrapper #togglebtn {
  width: 25px;
  height: 40px;
  display: block;
  background-color: #111;
  left: -25px;
  position: absolute;
  border: none;
  border-radius: 0% 0% 0% 50%;
  transition: 300ms cubic-bezier(0.4, 0, 1, 1) transform;
}

#record-map-wrapper #togglebtn span {
  background: #111;
  border-radius: 10px 0px 0px 10px;
  display: block;
  content: " ";
  width: 25px;
  height: 41px;
  top: 7px;
  position: absolute;
  right: 0px;
  transform: skewX(40deg);
  -ms-transform: skewX(40deg);
  -webkit-transform: skewY(40deg);
  z-index: -1;
}

#record-map-wrapper #togglebtn:before,
#record-map-wrapper #togglebtn:after {
  content: "";
  display: inline-block;
  height: 15px;
  width: 3px;
  background-color: white;
  border-radius: 10px;
  position: relative;
  transition: 300ms ease-in-out transform;
}

#record-map-wrapper #togglebtn:before {
  transform: rotate(45deg);
  left: 2px;
  top: 7px;
}

#record-map-wrapper #togglebtn:after {
  transform: rotate(-45deg);
  left: -1px;
  top: -2px;
}

#record-map-wrapper #togglebtn.close:before {
  transform: rotate(-45deg);
}

#record-map-wrapper #togglebtn.close:after {
  transform: rotate(45deg);
}

#record-map-wrapper #togglebtn:hover,
#record-map-wrapper #togglebtn:hover span {
  background-color: #444;
}


#record-map-wrapper .cond-id {
  color: var(--parkGreen) !important;
}

#record-map-wrapper .cond-list a.list-group-item:hover {
  background-color: #d1d1d1;
}

#record-map-wrapper .cond-list a.list-group-item {
  background-color: #e9e9e9;
}



#record-view-tab .nav-link {
  background-color: var(--parkGreen);
  border: 1px solid #dee2e6 !important;
  color: #000 !important;
}

#record-view-tab .nav-tabs {
  border: none !important;
  font-weight: 500;
}

#record-view-tab .nav-item.show .nav-link,
#record-view-tab .nav-link.active {
  color: #000;
  background-color: #fff;
  border-top: 5px solid var(--parkGreen) !important;
  position: relative;
}

#record-view-tab .nav-link.active:after {
  content: "";
  width: 100%;
  height: 5px;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: -3px;
}

#nav-record .txtArea label {
  margin-bottom: 0px;
}

#nav-record .txtArea textarea {
  width: 100%;
  min-height: 113px;
  margin-top: 9px;
  margin-bottom: -5px;
}

#record-view .cna-form .txtArea label {
  margin-bottom: 0px;
  margin-top: 0px;
}

.cna-form .txtArea textarea {
  width: 100%;
  min-height: 113px;
  margin-top: 8px;
  resize: none;
  border: 1px solid #ddd;
  border-radius: 6px;
}

#record-view .cna-form .form-label {
  font-size: 14px;
}


#record-view .score-list-group .list-group-item,
#record-view .score-list-group .list-group-item:last-child {
  border-bottom: 3px solid !important;
  /* Change the values as needed */
}

#record-view .score-nav-bar {
  border-bottom: 5px solid var(--parkGreen) !important;
  margin: -15px -28px 0 -28px;
  padding: 15px 20px;
  border-radius: 8px;
}

@media(max-width:768px) {
  #record-view .score-nav-bar {
    border-bottom: 5px solid var(--parkGreen) !important;
    border-top: 5px solid var(--parkGreen) !important;
    margin: 20px -28px 0 -28px;
  }
}

#record-view .score-nav-bar .nav-link {
  color: #000;
}

#record-view .score-nav-bar .nav-pills .nav-link.active,
#record-view .score-nav-bar .nav-pills .show>.nav-link {
  color: #000;
  background-color: var(--parkGreenLight);
}


#record-view .record-form-img-wrap img {
  max-height: 160px;
  text-align: center;
}


.recordImgModalBtn {
  background: none;
  border: none;
}


#record-view .recordImgModal img {
  width: auto;
  max-width: 100%;
  max-height: 80vh;
  border-radius: 6px;
}

#record-view .recordImgModal .modal-dialog {
  max-width: max-content !important;
  margin: 1.75rem auto;
}

#record-view .recordImgModal .modal-content {
  margin: 1.75rem;
  background-clip: unset !important;
}

#record-view .recordImgModal .modal-body {
  padding: 0 !important;
}

#record-view .recordImgModal .recordImgModalClose {
  background: var(--parkGreen) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;

  position: absolute;
  right: 0;

}

#record-view .safety-form .input-group-text {
  display: flex;
  align-items: center;
  width: 174px;
  white-space: normal;
  text-align: left;
}

.recTableWrap .pagination {
  --bs-pagination-color: var(--parkGreen);
  --bs-pagination-focus-color: var(--parkGreen);
  --bs-pagination-active-bg: var(--parkGreen);
  --bs-pagination-active-border-color: var(--parkGreen);
}

.recTableWrap .t-link {
  color: #000
}

.recTableWrap tr {
  cursor: pointer;
}


.ant-pagination {
  justify-content: flex-start !important;
}